import { EventLogger } from "@dpdgroupuk/mydpd-app";
import chunk from "lodash/chunk";
import { importsApi } from "~/apis";
import { CANNOT_WRITE_RECEIPT_FILE_$ } from "~/constants/strings";
import { ReceiptModel } from "~/models";
import { formatMessage } from "~/utils/string";

class ReceiptController {
  constructor({ localApis, logger }) {
    this.localApis = localApis;
    this.logger = logger || new EventLogger();
  }

  async printReceipts(receipts, shipmentReceiptTemplate, isOneReceiptFile) {
    const files = ReceiptModel.createReceiptFile(
      receipts,
      shipmentReceiptTemplate,
      isOneReceiptFile
    );

    for (const file of files) {
      try {
        await this.storeReceipt(
          shipmentReceiptTemplate.workingDir,
          file.filename,
          file.contents
        );
      } catch (err) {
        // don't propagate error
        // continue processing
      }
    }
  }

  async storeReceipt(receiptDir, filename, contents) {
    try {
      await this.localApis.fs.write(receiptDir, filename, contents);
    } catch (e) {
      this.logger.error(
        formatMessage(CANNOT_WRITE_RECEIPT_FILE_$, receiptDir),
        e
      );
      throw e;
    }
  }

  async generateReceiptsPerShipments(shipmentIds, params) {
    this.logger.info("Generating receipt file/s");
    const receipts = await this.getReceiptsByShipmentIds(shipmentIds); // load by chunks
    await this.printReceipts(receipts, params.shipmentReceiptTemplate);
  }

  async getReceiptsByShipmentIds(shipmentIds = []) {
    const chunkSize = 100;
    const chunksShipmentsId = chunk(shipmentIds, chunkSize);
    const parallelChunks = chunk(chunksShipmentsId, 5);
    const totalReceipts = [];

    for (let parallelChunk of parallelChunks) {
      const chunksReceipts = await Promise.all(
        parallelChunk.map(async chunkShipmentsId => {
          const { receipts } = await importsApi
            .getImportReceiptsByShipmentIds(chunkShipmentsId)
            .catch(async err => {
              this.logger.error("Unable to fetch receipt data", err);
              throw err;
            });

          return receipts;
        })
      );

      chunksReceipts.forEach(chunkReceipts =>
        totalReceipts.push(...chunkReceipts)
      );
    }

    return totalReceipts;
  }

  async getReceiptsByShipmentDate(shipmentDate) {
    const { receipts } = await importsApi
      .getReceiptByShipmentDate(shipmentDate)
      .catch(async err => {
        this.logger.error("Unable to fetch receipt data");
        throw err;
      });
    return receipts;
  }

  async getImportReceiptByJobId(jobId, printId) {
    return importsApi
      .getImportReceiptByJobId(jobId, {
        printId,
      })
      .catch(err => {
        this.logger.error("Unable to fetch receipt data");
        throw err;
      });
  }

  async generateReceipt(jobId, printId, { shipmentReceiptTemplate }) {
    const receiptDir = shipmentReceiptTemplate
      ? shipmentReceiptTemplate.workingDir
      : null;
    const receiptFileExtension = shipmentReceiptTemplate.fileExtension;
    const { oneReceiptFileName, receiptData } =
      await this.getImportReceiptByJobId(jobId, printId);
    const receiptFileName = oneReceiptFileName + receiptFileExtension;

    if (receiptData && receiptData.length) {
      this.logger.info(`Generating receipt file ${receiptFileName}`, {
        receiptFileName,
      });

      let receiptFileContent = receiptData
        .join("\r\n")
        .replace(/\\t/g, String.fromCharCode(9));
      receiptFileContent += "\r\n"; // CR00003231 - Add CRLF at the end of the receipt file

      await this.storeReceipt(receiptDir, receiptFileName, receiptFileContent);
    }
  }
}

export default ReceiptController;
